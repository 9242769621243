<template>
  <section class="iq-rpt-0 overflow-hidden overview-block-pt banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 order-lg-1 order-md-1 order-sm-1 order-xs-1 mx-auto align-self-center wow fadeInUp" data-wow-delay=".5s">
          <h1 class="text-white mb-3">About RACOINS</h1>
          <p class="mb-5">Discover the story of RACOINS, your trusted trading platform. We are dedicated to providing the best trading experience through innovative AI-powered solutions. Our goal is to empower traders and investors with cutting-edge technology and data-driven insights.</p>
          <a class="landing-button btn-primary" href="#">Learn more</a>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 order-lg-2 order-md-2 order-sm-2 order-xs-2 mx-auto wow fadeInUp" data-wow-delay=".3s">
          <img src="@/assets/images/landing-page/landing-3/ig2.png" class="img-fluid" alt="images">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUs',
  data () {
    return {
      abouts: [
        // Ajoutez les données des compteurs au besoin
      ]
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1170em; /* Bootstrap Large devices Desktops (≥1200px) container width in em */
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -8em;
}

.banner {
  padding-top: 1.125em; /* Padding in em (adjust as needed) */
}

/* Ajout de styles pour la mise en page responsive */
@media (max-width: 120em) {
  /* Pour Large devices (≥1200px) */
  .col-lg-6.align-self-center {
    margin-left: 0;
  }
}

@media (max-width: 99.2em) {
  /* Pour Medium devices Desktops (≥992px) */
  .col-md-8 {
    width: 100%;
  }
}

@media (max-width: 76.8em) {
  /* Pour Small devices Tablets (≥768px) */
  .col-sm-10,
  .col-md-8 {
    width: 100%;
  }

  .banner {
    padding-top: 3.125em; /* Padding in em (adjust as needed) */
  }

  /* Ajout de décalage entre le bouton "En savoir plus" et l'image */
  .col-sm-10 .btn-primary {
    margin-top: 1.25em;
    margin-bottom: 1.25em; /* Ajout d'un espace en bas du bouton */
  }
}

@media (max-width: 57.6em) {
  /* Pour Extra small devices Phones (<768px) */
  .col-xs-12,
  .col-sm-10,
  .col-md-8 {
    width: 100%;
  }
}
</style>
