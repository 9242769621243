<template>
  <section id="testimonial" class="iq-testimonial pt-0">
    <div class="container p-0">
      <div class="row no-gutters">
        <div class="col-sm-12">
          <div class="title-box text-center">
            <h6 class="title text-white">
              Testimonial
            </h6>
            <h2 class="text-white">
              What Our Clients Say
            </h2>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div
          class="owl-carousel owl-theme owl-loaded"
          data-dots="true"
          data-nav="false"
          data-items="1"
          data-xs-items="1"
          data-sm-items="1"
          data-md-items="1"
          data-lg-items="1"
          data-autoplay="true"
        >
          <BlackCardStyle5>
            <template v-slot:cardBody>
              "I've been using Racoins for a while now, and it has truly revolutionized my trading experience. The AI-powered strategies are incredibly effective, and I've seen substantial profits."
            </template>
            <template v-slot:cardMedia>
              <img src="@/assets/images/landing-page/landing-3/16.jpg" class="img-fluid mb-5 img-shap " alt="">
            </template>
            <template v-slot:cardFooter>
              <h4>John Doe</h4>
              <p>Full-time Trader</p>
            </template>
          </BlackCardStyle5>
          <BlackCardStyle5>
            <template v-slot:cardBody>
              "Racoins has made trading so much easier for me. Their platform is user-friendly, and the real-time data and historical analysis tools are fantastic. Highly recommended!"
            </template>
            <template v-slot:cardMedia>
              <img src="@/assets/images/landing-page/landing-3/17.jpg" class="img-fluid mb-5 img-shap " alt="">
            </template>
            <template v-slot:cardFooter>
              <h4>Lisa Smith</h4>
              <p>Part-time Trader</p>
            </template>
          </BlackCardStyle5>
          <BlackCardStyle5>
            <template v-slot:cardBody>
              "I've tried several trading platforms, but Racoins stands out. It's secure, responsive, and the design is top-notch. It's a pleasure to use for my trading activities."
            </template>
            <template v-slot:cardMedia>
              <img src="@/assets/images/landing-page/landing-3/18.jpg" class="img-fluid mb-5 img-shap " alt="">
            </template>
            <template v-slot:cardFooter>
              <h4>Emily Johnson</h4>
              <p>Day Trader</p>
            </template>
          </BlackCardStyle5>
          <BlackCardStyle5>
            <template v-slot:cardBody>
              "With Racoins, I feel more confident in my trading decisions. The AI-powered insights and fast performance have helped me make informed choices and increase my profits."
            </template>
            <template v-slot:cardMedia>
              <img src="@/assets/images/landing-page/landing-3/19.jpg" class="img-fluid mb-5 img-shap " alt="">
            </template>
            <template v-slot:cardFooter>
              <h4>Michael Brown</h4>
              <p>Crypto Enthusiast</p>
            </template>
          </BlackCardStyle5>
          <BlackCardStyle5>
            <template v-slot:cardBody>
              "Racoins has exceeded my expectations. It's fully responsive, and the user experience is fantastic. I'm impressed with their platform and its design."
            </template>
            <template v-slot:cardMedia>
              <img src="@/assets/images/landing-page/landing-3/20.jpg" class="img-fluid mb-5 img-shap " alt="">
            </template>
            <template v-slot:cardFooter>
              <h4>Sarah Miller</h4>
              <p>Investor</p>
            </template>
          </BlackCardStyle5>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import BlackCardStyle5 from '../../../../components/frontend/cards/BlackCardStyle5'
export default {
  name: 'Testimonial',
  components:{ BlackCardStyle5 }
}
</script>
