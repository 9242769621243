import { render, staticRenderFns } from "./Feature.vue?vue&type=template&id=3bf055ca&scoped=true&"
import script from "./Feature.vue?vue&type=script&lang=js&"
export * from "./Feature.vue?vue&type=script&lang=js&"
import style0 from "./Feature.vue?vue&type=style&index=0&id=3bf055ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf055ca",
  null
  
)

export default component.exports