<template>
  <section id="feature" class="iq-features iq-rpt-0">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="title-box text-center">
            <h6 class="title text-white">Features</h6>
            <h2 class="text-white">Great Features</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div v-for="(feature, index) in features" :key="index" class="col-lg-4 col-md-6 mb-3">
          <BlackCardStyle2>
            <template v-slot:cardImage>
              <img :src="feature.src" class="img-fluid" alt="images">
            </template>
            <template v-slot:cardTitle>
              <router-link :to="feature.link" class="card-title">{{ feature.title }}</router-link>
            </template>
            <template v-slot:cardContent>
              <p class="card-text" style="text-size-adjust: 20px;">{{ feature.description }}</p>
            </template>
          </BlackCardStyle2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BlackCardStyle2 from '../../../../components/frontend/cards/BlackCardStyle2'

export default {
  name: 'Feature',
  components: { BlackCardStyle2 },
  data() {
    return {
      features: [
        {
          src: require('@/assets/images/landing-page/landing-3/04.png'),
          title: 'Reward Functions for AI Trading Agents',
          description: 'Explore a variety of reward functions employed by our AI trading agents to evaluate and optimize trading strategies. These reward functions play a crucial role in reinforcing desired behaviors and enhancing the performance of AI-driven trading algorithms. Gain insights into the different reward functions and their impact on AI trading decisions.',
          link: { name: 'rewardFunctions' }
        },
        {
          src: require('@/assets/images/landing-page/landing-3/05.png'),
          title: 'Fine-Tune Your AI Agent with Hyperparameter Optimization',
          description: 'Enhance the performance of your AI agent by customizing its hyperparameters. Fine-tune critical settings to optimize learning rates, batch sizes, network architectures, and more. Achieve better results and tailor your agent’s behavior to meet your specific requirements. Experiment, iterate, and empower your AI with precision using hyperparameter optimization.',
          link: { name: 'HyperParams' }
        },
        {
          src: require('@/assets/images/landing-page/landing-3/04.png'),
          title: 'Candlestick Patterns for AI Analysis',
          description: 'Discover a wide range of candlestick patterns utilized in our AI-driven analysis. These candlestick patterns are essential for identifying potential price movements and trend reversals in financial markets. Dive into the world of candlestick patterns and understand how our AI incorporates them to make more accurate predictions.',
          link: { name: 'Patterns' }
        }
      ]
    }
  }
}
</script>

<style scoped>
.iq-features.iq-rpt-0 {
  margin-top: 0px;
}

@media (max-width: 120em) {
  .col-sm-12 {
    margin-top: -2em;
  }
}

@media (max-width: 430px) {
  .iq-features.col-sm-12 {
    .title-box {
      margin-top: -10px;
    }
  }

  .col-lg-4 {
    margin-bottom: 1.5em;
  }
}
</style>
