<template>
  <section class="iq-rpt-0 overflow-hidden overview-block-pt banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 order-lg-1 order-md-1 order-sm-1 order-xs-1 mx-auto align-self-center wow fadeInUp" data-wow-delay=".5s">
          <h1 class="text-white mb-3">Software Features</h1>
          <div class="row">
            <p class="mb-5">Discover the exceptional features that make RACOINS stand out. Our platform offers a wide range of tools and capabilities designed to empower traders and investors. Explore how our innovative software can elevate your trading experience.</p>
          </div>
          <div class="row">
            <div v-for="(feature, index) in features" :key="index" class="col-lg-6 col-md-6">
              <div class="features">
                <div class="feature-info">
                  <h5 class="text-white">{{ feature.title }}</h5>
                  <p>{{ feature.subtitle }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 order-lg-2 order-md-2 order-sm-2 order-xs-2 mx-auto wow fadeInUp" data-wow-delay=".3s">
          <img src="@/assets/images/landing-page/landing-3/Capture2.png" class="img-fluid left-image" alt="images">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutFeature',
  components:{
    
  },
  data () {
    return {
      features: [
        {
          title: 'Advanced Data Analysis',
          subtitle: 'Unlock the power of data.',
        },
        {
          title: 'Efficient Time Management',
          subtitle: 'imize your time usage.',
        },
        {
          title: 'Report Management',
          subtitle: 'Streamline your report organization.',
        },
        {
          title: 'Robust Data Security',
          subtitle: 'Protect and secure your valuable data.',
        }
      ]
    }
  }
}
</script>

<style scoped>
section.banner>.container {
  max-width: 1170em;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -8em;
}

.banner {
  padding-top: 1.125em;
}

.left-image {
  transform: translateX(-38em);
}

.col-lg-6.align-self-center {
  transform: translateX(49em);
  max-width: 100%; /* Ajuster si nécessaire */
}

@media (max-width: 120em) {
  .col-lg-6.align-self-center {
    margin-left: 0;
  }
}

@media (max-width: 99.2em) {
  .col-md-8 {
    width: 100%;
  }
}

@media (max-width: 76.8em) {
  .col-sm-10,
  .col-md-8 {
    width: 100%;
  }

  .banner {
    padding-top: 3.125em;
  }

  .col-sm-10 .btn-primary {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
}

@media (max-width: 57.6em) {
  .col-xs-12,
  .col-sm-10,
  .col-md-8 {
    width: 100%;
  }
}

</style>
