<template>
  <section class="iq-rpt-0 overflow-hidden overview-block-pt banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 order-lg-1 order-md-1 order-sm-1 order-xs-1 mx-auto align-self-center wow fadeInUp" data-wow-delay=".5s">
          <h1 class="text-white mb-3">Revolutionize your trading with AI-based strategies.</h1>
          <p class="mb-5">Discover the future of trading with our AI-powered platform. We specialize in creating powerful trading strategies using AI robots that can transform your trading experience.</p>
          <a class="landing-button btn-primary" href="#">Learn more</a>
        </div>
        <div class="col-lg-6 col-md-8 col-sm-10 col-xs-12 order-lg-2 order-md-2 order-sm-2 order-xs-2 mx-auto wow fadeInUp" data-wow-delay=".3s">
          <img src="@/assets/images/landing-page/landing-3/Capture1.png" class="img-fluid" alt="images">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
  }
}
</script>

<style scoped>

.container {

  max-width: 1170em; 
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -8em;
}

.banner {
  padding-top: 1em; /* Padding in em (adjust as needed) */
}

@media (max-width: 120em) {
  .col-lg-6.align-self-center {
    margin-left: 0;
  }
}

@media (max-width: 99.2em) {
  .col-md-8 {
    width: 100%;
  }
}

@media (max-width: 76.8em) {
  .col-sm-10,
  .col-md-8 {
    width: 100%;
  }

  .banner {
    padding-top: 3.125em; /* Padding in em (adjust as needed) */
  }

  .col-sm-10 .btn-primary {
    margin-top: 1.25em;
    margin-bottom: 1.25em; /* Ajout d'un espace en bas du bouton */
  }
}

@media (max-width: 57.6em) {
  .col-xs-12,
  .col-sm-10,
  .col-md-8 {
    width: 100%;
  }
}
</style>
