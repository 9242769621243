<template>
    <section class="iq-pricing pt-0" id="pricing">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-sm-12">
            <div class="title-box text-center">
              <h6 class="title text-white">Pricing</h6>
              <h2 class="text-white">Our Pricing</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="pricing-tab btn-group" role="group">
              <button
                type="button"
                class="btn btn-primary"
                :class="{ active: activeTab === 'monthly' }"
                @click="togglePricing('monthly')"
              >
                Monthly
              </button>
              <button
                type="button"
                class="btn btn-primary"
                :class="{ active: activeTab === 'yearly' }"
                @click="togglePricing('yearly')"
              >
                Yearly
              </button>
            </div>
            <tab-content id="pills-tabContent">
              <tab-content-item :active="activeTab === 'monthly'" id="monthly-tab" aria-labelled-by="monthly" role="tabpanel">
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="iq-pricing-table">
                      <div class="pricing-header">
                        <h4 class="mb-5">Personal</h4>
                        <h2>19$<span>/ Per Month</span></h2>
                      </div>
                      <ul class="list-unstyled">
                        <li class=""><i class="fas fa-check-circle"></i>Access to basic trading strategies.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Real-time market data.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Basic customer support.</li>
                        <li class=""><i class="fas fa-times-circle"></i>Advanced analytics.</li>
                      </ul>
                      <a class="landing-button btn-primary mt-5" href="#">Choose Plan</a>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="iq-pricing-table">
                      <div class="pricing-header">
                        <h4 class="mb-5">Business</h4>
                        <h2>49$<span>/ Per Month</span></h2>
                      </div>
                      <ul class="list-unstyled">
                        <li class=""><i class="fas fa-check-circle"></i>Access to advanced trading strategies.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Real-time market data.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Premium customer support.</li>
                        <li class=""><i class="fas fa-times-circle"></i>Advanced analytics.</li>
                      </ul>
                      <a class="landing-button btn-primary mt-5" href="#">Choose Plan</a>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="iq-pricing-table">
                      <div class="pricing-header">
                        <h4 class="mb-5">Enterprice</h4>
                        <h2>99$<span>/ Per Month</span></h2>
                      </div>
                      <ul class="list-unstyled">
                        <li class=""><i class="fas fa-check-circle"></i>Access to premium trading strategies.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Real-time market data.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Premium customer support.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Advanced analytics.</li>
                      </ul>
                      <a class="landing-button btn-primary mt-5" href="#">Choose Plan</a>
                    </div>
                  </div>
                </div>
              </tab-content-item>
              <tab-content-item :active="activeTab === 'yearly'" id="yearly-tab" aria-labelled-by="yearly" role="tabpanel">
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="iq-pricing-table">
                      <div class="pricing-header">
                        <h4 class="mb-5">Personal</h4>
                        <h2>199$<span>/ Per Year</span></h2>
                      </div>
                      <ul class="list-unstyled">
                        <li class=""><i class="fas fa-check-circle"></i>Access to basic trading strategies.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Real-time market data.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Basic customer support.</li>
                        <li class=""><i class="fas fa-times-circle"></i>Advanced analytics.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Additional advantage 1.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Additional advantage 2.</li>
                      </ul>
                      <a class="landing-button btn-primary mt-5" href="#">Choose Plan</a>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="iq-pricing-table">
                      <div class="pricing-header">
                        <h4 class="mb-5">Pro</h4>
                        <h2>499$<span>/ Per Year</span></h2>
                      </div>
                      <ul class="list-unstyled">
                        <li class=""><i class="fas fa-check-circle"></i>Access to advanced trading strategies.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Real-time market data.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Premium customer support.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Advanced analytics.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Additional advantage 1.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Additional advantage 2.</li>
                      </ul>
                      <a class="landing-button btn-primary mt-5" href="#">Choose Plan</a>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="iq-pricing-table">
                      <div class="pricing-header">
                        <h4 class="mb-5">Premium</h4>
                        <h2>999$<span>/ Per Year</span></h2>
                      </div>
                      <ul class="list-unstyled">
                        <li class=""><i class="fas fa-check-circle"></i>Access to premium trading strategies.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Real-time market data.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Premium customer support.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Advanced analytics.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Additional advantage 1.</li>
                        <li class=""><i class="fas fa-check-circle"></i>Additional advantage 2.</li>
                      </ul>
                      <a class="landing-button btn-primary mt-5" href="#">Choose Plan</a>
                    </div>
                  </div>
                </div>
              </tab-content-item>
            </tab-content>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'Pricing',
    data() {
      return {
        activeTab: 'monthly',
      };
    },
    methods: {
      togglePricing(tab) {
        this.activeTab = tab;
      },
    },
  };
  </script>
  